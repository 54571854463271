import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button,Loader, Divider, Form, Grid, Segment,Header,Icon,Input,Container,Menu ,Label} from 'semantic-ui-react'


class LoyaltyData extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loyaltyData: '',
            loading: true,
            tax_percentage: '',
            loyalty_index: '',
            error: null,
            message: null,
            color: null,
            submitted: false,
            blue: '',
            bronze: '',
            silver: '',
            gold: '',
            platinum: '',
          };
     
      }
    
   
  
    componentDidMount() {
      fetch("https://aoneext.com/v1/getloyaltydata")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              
              loyaltyData: result,
              tax_percentage: result.tax_percentage * 100,
              loyalty_index: result.loyalty_index * 100,
              blue: result.loyalty_benchmarks.blue,
              bronze: result.loyalty_benchmarks.bronze,
              silver: result.loyalty_benchmarks.silver,
              gold: result.loyalty_benchmarks.gold,
              platinum: result.loyalty_benchmarks.platinum,
              loading: false
            });
            console.log(result)
          },
          
          (error) => {
            this.setState({
              loading: false,
              error
            });
          }
        )
    }

      handleChangeTax = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("settax: "+event.target.value)
      }
      handleChangeIndex = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("setindex: "+event.target.value)

      }
      handleChangeBlue = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("setindex: "+event.target.value)

      }
      handleChangeBronze = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("setindex: "+event.target.value)

      }
      handleChangeSilver = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("setindex: "+event.target.value)

      }
      handleChangeGold = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("setindex: "+event.target.value)

      }
      handleChangePlatinum = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log("setindex: "+event.target.value)

      }



      handleSubmit = () => {
       

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "tax_percentage": this.state.tax_percentage ,"loyalty_index" : this.state.loyalty_index ,"loyalty_benchmarks" : {
              "blue" : this.state.blue,
              "bronze" : this.state.bronze,
              "silver" : this.state.silver,
              "gold" : this.state.gold,
              "platinum" : this.state.platinum

            }})
        };
        fetch('https://aoneext.com/v1/updateloyaltydata/', requestOptions)
        .then(response => this.setState({ color: "red", message: "post failed:"+response.json(),submitted: true }))        
        .then(data => this.setState({ color: "green",message: data,submitted: true  }))
  
        }
     
      




    
  
    render() {
    

        return (




            <Container fluid>
                <Menu>
                <Menu.Item>
                <Header as='h1'>Aone Loyalty</Header>    </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item>
            </Menu.Item>
            </Menu.Menu>
          </Menu>
        
              <div className="ui divider"></div>
            <Segment placeholder>
              
        
              <Grid columns={2} stackable textAlign='center'>
              <Divider vertical>--></Divider>
              <Grid.Row verticalAlign='middle'>
        
               <Grid.Column>
                       
               <Header icon>
                    <Icon name='star' />
                    Configure tax,loyalty index and loyalty benchmarks    
                  </Header>
       
              
              </Grid.Column>

              {
          this.state.loading ? <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
        /> :  
              <Grid.Column>            

                <h3>Edit Loyalty Data</h3>
                <Form onSubmit={this.handleSubmit}>


                <Form.Field>
                <label>Tax percentage( existing value is { this.state.tax_percentage})</label>
                <Input label={{ basic: true, content: '%' }} labelPosition='right' placeholder={ this.state.tax_percentage} value={this.state.value} name="tax_percentage" onChange={this.handleChangeTax} />
                </Form.Field>
                <Form.Field>
                <label>Loyalty index(existing value is { this.state.loyalty_index}) </label>
                <Input label={{ basic: true, content: '%' }} labelPosition='right' placeholder={ this.state.loyalty_index}  value={this.state.value} name="loyalty_index" onChange={this.handleChangeIndex}/>
                </Form.Field>
                <Form.Field>
                <label>Blue( existing value is { this.state.blue})</label>
                <Input  placeholder={ this.state.blue} value={this.state.value} name="blue" onChange={this.handleChangeBlue} />
                </Form.Field>
                <Form.Field>
                <label>Bronze( existing value is { this.state.bronze})</label>
                <Input  placeholder={ this.state.bronze} value={this.state.value} name="bronze" onChange={this.handleChangeBronze} />
                </Form.Field>
                <Form.Field>
                <label>Silver( existing value is { this.state.silver})</label>
                <Input placeholder={ this.state.silver} value={this.state.value} name="silver" onChange={this.handleChangeSilver} />
                </Form.Field>
                <Form.Field>
                <label>Gold( existing value is { this.state.gold})</label>
                <Input  placeholder={ this.state.gold} value={this.state.value} name="gold" onChange={this.handleChangeGold} />
                </Form.Field>
                <Form.Field>
                <label>Platinum( existing value is { this.state.platinum})</label>
                <Input  placeholder={this.state.platinum} value={this.state.value} name="platinum" onChange={this.handleChangePlatinum} />
                </Form.Field>
                        
          
 
          { this.state.submitted ?  <Label  className="result"><h3>submitted!!!</h3> </Label> : <Button type='submit'>Submit</Button>}

        </Form>
                
                
              </Grid.Column>
    }
              </Grid.Row>
        
              </Grid>
            
            </Segment>
            </Container>
          );
        }
  
        
      }
     
      
    
  

  export default  LoyaltyData;